import React from "react";
import {generateState, saveState} from "../../hooks/useAuthState";

function AuthImageButton(props) {

    const {authorizeUrl, clientId, redirectUri, imageName, scope, responseType, alt} = props;

    const onClickImage = () => {
        const state = generateState()
        const url = `${authorizeUrl}?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUri}&state=${state}`
        saveState(state)
        window.open(url, "_self")
    }

    return (
        <img height="50px" src={imageName} alt={alt} onClick={onClickImage}></img>
    )
}

export function MailAuthImageButton() {
    return (
        <AuthImageButton
            authorizeUrl="https://oauth.mail.ru/login"
            clientId="264c4863334f470691eacf86ddb85017"
            redirectUri={`${process.env.REACT_APP_URL}/auth/mail`}
            imageName="mail.png"
            scope="userinfo"
            responseType="code"
            alt="Авторизация через Mail.ru"/>)
}

export function VkAuthImageButton() {
    return (
        <AuthImageButton
            authorizeUrl="https://oauth.vk.com/authorize"
            clientId="51858192"
            redirectUri={`${process.env.REACT_APP_URL}/auth/vk`}
            imageName="vk.png"
            scope="userinfo"
            responseType="code"
            alt="Авторизация через ВКонтакте"/>)
}

export function OkAuthImageButton() {
    return (
        <AuthImageButton
            authorizeUrl="https://connect.ok.ru/oauth/authorize"
            clientId="512002101099"
            redirectUri={`${process.env.REACT_APP_URL}/auth/ok`}
            imageName="ok.png"
            scope="userinfo"
            responseType="code"
            alt="Авторизация через Одноклассники"/>)
}

export function GoogleAuthImageButton() {
    return (
        <AuthImageButton
            authorizeUrl="https://accounts.google.com/o/oauth2/v2/auth"
            clientId="192503074122-j998okvelo7mgiqeru3ko9hbkd2ut767.apps.googleusercontent.com"
            redirectUri={`${process.env.REACT_APP_URL}/auth/google`}
            imageName="google.png"
            scope="https://www.googleapis.com/auth/userinfo.profile"
            responseType="token"
            alt="Авторизация через Google"/>)
}

export function TelegramAuthImageButton() {
    return (
        <AuthImageButton
            authorizeUrl="https://oauth.mail.ru/login"
            clientId="264c4863334f470691eacf86ddb85017"
            redirectUri={`${process.env.REACT_APP_URL}/auth/telegram`}
            imageName="tg.png"
            scope="userinfo"
            responseType="code"
            alt="Авторизация через Telegram"/>)
}

export function YandexAuthImageButton() {
    return (
        <AuthImageButton
            authorizeUrl="https://oauth.yandex.ru/authorize"
            clientId="2a011e8c1e864a5d83e99acb74a594f6"
            redirectUri={`${process.env.REACT_APP_URL}/auth/yandex`}
            imageName="yandex.png"
            scope="login:info"
            responseType="code"
            alt="Авторизация через Yandex"/>)
}

export default AuthImageButton;
