import {useCookies} from "react-cookie";

export async function GetUsersPermit() {
    const [cookies, setCookie] = useCookies(['token'])

    let token = (cookies.token === null)
        ? null
        : "Bearer " + cookies.token

    const response = await fetch(`${process.env.REACT_APP_API_CONCRETE_PRICE_URL}/user/hasPermit`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Authorization": token
        }
    })
    const responseJson = await response.json()
    return responseJson["hasPermit"]
}
