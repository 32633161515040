import React from "react";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import FbsNameButtonGroup from "./buttonGroups/FbsNameButtonGroup";
import {Col, Container, Row} from "react-bootstrap";
import FbsNameDropdownButtonGroup from "./buttonGroups/FbsNameDropdownButtonGroup";

function FbsNameButtonToolbar() {

    return (
        <Container>
            <Row>
                <Col>
                    <ButtonToolbar className="justify-content-center">
                        <FbsNameButtonGroup groupId='fbs3'/>
                        <FbsNameButtonGroup groupId='fbs4'/>
                        <FbsNameButtonGroup groupId='fbs5'/>
                        <FbsNameButtonGroup groupId='fbs6'/>
                        <FbsNameButtonGroup groupId='fbs8'/>
                    </ButtonToolbar>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FbsNameDropdownButtonGroup groupId='fbsHeight3' className="w-100"/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FbsNameDropdownButtonGroup groupId='gardenFbs' className="w-100"/>
                </Col>
            </Row>
        </Container>
    )
}

export default FbsNameButtonToolbar;