import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import FbsNameDropdownButton from "../buttons/FbsNameDropdownButton";
import FbsGroup from "../../constants/FbsGroups";

function FbsNameDropdownButtonGroup(props) {

    return (
        <ButtonGroup className="w-100" aria-label="Non-standard fbs button group">
            <FbsNameDropdownButton fbsGroup = {FbsGroup(props.groupId)} key={props.groupId}/>
        </ButtonGroup>

    )
}

export default FbsNameDropdownButtonGroup