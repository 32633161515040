import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppBrowserRouter from "./routes/AppBrowserRouter";


ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <AppBrowserRouter/>
    </React.StrictMode>
);
