import {Accordion, Card} from "react-bootstrap";
import React from "react";

function OfferButton(props) {
    return (
        <>
            <Accordion>
                <Card>
                    <Card.Header>
                        <a href="/offer">Есть еще дешевле на {props.demoPriceDelta} руб. >>></a>
                    </Card.Header>
                </Card>
            </Accordion>
        </>
    )
}

export default OfferButton;