import {Col, Container, Row} from "react-bootstrap";
import React from "react";

function SellerCard(props) {

    const renderPhoneNumbers = (response) => {
        return (
            response["sellerPhones"].map(phone => sellerPhones(phone["phoneNumber"])))
    }

    const sellerPhones = (phoneNumber) => {
        return (
            <>
                {phoneNumber} <br/>
            </>
        )
    }

    return (
        <Container className="text-start">
            <Row>
                <Col>
                    {props.response["factoryAddresses"].map(address =>
                        <>
                            <h6>{address["factoryName"]}</h6>
                            <p>г. {address["cityName"]}, ул. {address["streetName"]}, д. {address["buildingNumber"]},
                                стр. {address["housingNumber"]}<br/>
                                <a href={address["mapLink"]} target="_blank">Посмотреть на карте </a>
                            </p>
                        </>)
                    }
                    <p>Телефоны:<br/>
                        {renderPhoneNumbers(props.response)}</p>

                    {props.response["sellerEmails"].map(mail => <p><a
                        href={"mailto:" + mail["email"]}>{mail["email"]}</a></p>)}
                    <a href={props.response["priceListLink"]} target="_blank">Ссылка на сайт продавца</a>
                </Col>
            </Row>
        </Container>
    );
}

export default SellerCard;