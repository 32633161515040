import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import FbsNameButton from "../buttons/FbsNameButton";
import FbsGroup from "../../constants/FbsGroups";

function FbsNameButtonGroup(props) {
    let groupFbsNames = FbsGroup(props.groupId).names
    let groupLabel = props.groupId + " button group"
    return (
        <ButtonGroup className="ms-1 w-100" aria-label={groupLabel}>
            {groupFbsNames.map((fbsName) => <FbsNameButton key={fbsName} buttonText={fbsName}/>)}
        </ButtonGroup>
    )
}

export default FbsNameButtonGroup