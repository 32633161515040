import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import FbsNameButtonToolbar from "../components/FbsNameButtonToolbar";
import Content from "../components/Content";

function HomePage() {
    return (
        <>
            <Container id="cover-container" className="text-center ">
                <Row>
                    <Col>
                        <h5> Цены на ФБС. Более 20 производителей</h5>
                    </Col>
                </Row>
            </Container>
            <Container id="search-price-container">
                <Row className="mt-2">
                    <Col>
                        <FbsNameButtonToolbar/>
                    </Col>
                    <Col>
                        <Content/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default HomePage