import React from "react";
import {Container, Navbar} from "react-bootstrap";

export default function MainNavbar() {
    return (
        <Navbar className="mb-3" expand="lg" bg="dark" data-bs-theme="dark">
            <Container>
                <Navbar.Brand className="text-center" href="/">Фундаментные блоки. Новосибирск</Navbar.Brand>
                {/*<Navbar.Toggle aria-controls="basic-navbar-nav"/>*/}
            </Container>
        </Navbar>
    );
}