import React from "react";
import ProductPriceList from "./ProductPriceList";
import OfferButton from "./buttons/OfferButton";

function PriceListContainer(props) {

    if (props.priceListResponse["demoPriceList"] === true) {
        return (
            <>
                <ProductPriceList priceListResponse = {props.priceListResponse}/>
                <OfferButton demoPriceDelta = {props.priceListResponse["demoPriceDelta"]} />
            </>
        )
    }
    return (
        <>
            <ProductPriceList priceListResponse = {props.priceListResponse}/>
        </>)

}

export default PriceListContainer;