import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import {YMInitializer} from "react-yandex-metrika";
import MainNavbar from "../components/MainNavbar";
import Footer from "../components/Footer";
import {Outlet} from "react-router-dom";

export default function App() {
    return (
        <>
            <YMInitializer accounts={[96167350]}/>
            <MainNavbar/>
            <Outlet/>
            <Footer/>
        </>);
}