import React from 'react'
import Button from 'react-bootstrap/Button';

import {useNavigate} from "react-router-dom";
import ym from "react-yandex-metrika";


function FbsNameButton(props) {
    const navigate = useNavigate();

    const onClick = () => {
        sendDataToYandexMetric()
        openPricePage()
    }

    const openPricePage = () => {
        navigate("/prices/" + props.buttonText);
    }

    const sendDataToYandexMetric = () => {
        const goalParams = {productName: props.buttonText};
        ym('reachGoal', 'price_button', goalParams);
        return true;
    }

    return <Button className="mb-1 ms-1" size="sm" variant="secondary" onClick={onClick}>{props.buttonText}</Button>
}

export default FbsNameButton;