import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import {
    GoogleAuthImageButton,
    MailAuthImageButton,
    OkAuthImageButton,
    VkAuthImageButton,
    YandexAuthImageButton
} from "../components/buttons/AuthImageButton";

function OfferPage() {

    return (
        <>
            <Container>
                <Col className="text-center">
                    <p className="my-4">Просмотр цен всех производителей доступен после авторизации</p>
                    {/*<p>Стоимость доступа к услугам сервиса на 1 месяц - 300 рублей.</p>*/}
                    <p>Авторизуйтесь через</p>
                </Col>
            </Container>
            <Container className="my-4">
                <Row className="text-center row-cols-5">
                    {/*<Col>*/}
                    {/*    <TelegramAuthImageButton/>*/}
                    {/*</Col>*/}
                    <Col>
                        <YandexAuthImageButton/>
                    </Col>
                    <Col>
                        <GoogleAuthImageButton/>
                    </Col>
                    <Col>
                        <VkAuthImageButton/>
                    </Col>
                    <Col>
                        <MailAuthImageButton/>
                    </Col>
                    <Col>
                        <OkAuthImageButton/>
                    </Col>
                </Row>
            </Container>
            {/*<Container>*/}
            {/*    <Col className="text-center">*/}
            {/*        <p>После авторизации Вы будете перенаправлены на стартовую страницу, повтороно выбреите наименование*/}
            {/*            ФБС и получите полный список цен</p>*/}
            {/*    </Col>*/}
            {/*</Container>*/}
        </>

    )
}

export default OfferPage