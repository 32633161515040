import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom";
import App from "./App";
import HomePage from "./HomePage";
import PaymentPage from "./PaymentPage";
import AuthPage from "./AuthPage";
import CheckLoginPage from "./CheckLoginPage";
import {GetUsersPermit} from "../dataLoader/UserDataLoader";
import OfertaPage from "./OfertaPage";
import OfferPage from "./OfferPage";
import PricePage from "./PricePage";
import CheckPaymentPage from "./CheckPaymentPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import React from "react";

export default function AppBrowserRouter() {

    const checkLoginLoader = async () => {
        const hasPermit = GetUsersPermit();
        if (hasPermit === true) {
            let redirectPathName = JSON.parse(sessionStorage.getItem("last_price_page"))["value"];
            return redirect(redirectPathName)
        }
        return redirect('/payment')
    }

    const router = createBrowserRouter([
        {
            path: "/",
            element: <App/>,
            children: [
                {
                    index: true,
                    element: <HomePage/>,
                },
                {
                    path: "/payment",
                    element: <PaymentPage/>,
                },
                {
                    path: "/auth/:authMethod",
                    element: <AuthPage/>,
                },
                {
                    path: "/checkLogin",
                    element: <CheckLoginPage/>,
                    loader: checkLoginLoader
                },
                {
                    path: "/oferta",
                    element: <OfertaPage/>,
                },
                {
                    path: "/offer",
                    element: <OfferPage/>,
                },
                {
                    path: "/prices/:productName",
                    element: <PricePage/>,
                },
                {
                    path: "/checkPayment",
                    element: <CheckPaymentPage/>,
                },
                {
                    path: "/privacyPolicy",
                    element: <PrivacyPolicyPage/>,
                }
            ]
        },
    ]);

    return <RouterProvider router={router}/>

}