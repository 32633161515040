import {useEffect} from "react";

function CheckPaymentPage() {
    let redirectUrl = JSON.parse(sessionStorage.getItem("last_price_page"))["value"];

    useEffect(() => {
        window.open(redirectUrl, "_self")
    })
}

export default CheckPaymentPage;