import {Accordion, Card} from "react-bootstrap";
import PriceAccordion from "./PriceAccordion";
import React from "react";

function ProductPriceList(props) {
    return (
        <>
            <Accordion>
                <Card>
                    <Card.Header>
                        <p align="center">Доступно для
                            просмотра {props.priceListResponse["priceListSize"]} из {props.priceListResponse["fullPriceListSize"]}</p>
                    </Card.Header>
                </Card>
            </Accordion>
            {props.priceListResponse["priceListItems"].map((priceListItem) => <PriceAccordion data={priceListItem} key={priceListItem['priceListItemId']}/>)}
        </>
    )
}

export default ProductPriceList;