import React from "react";
import {Container, Nav, Navbar, Row} from "react-bootstrap";

function Footer() {
    return (
        <Container>
            <Row>
                    <Navbar className="justify-content-center p-10">
                        <Nav>
                            <Nav.Link className="text-center" href ="/oferta" color="inherit">Публичная оферта</Nav.Link>{' '}
                            <Nav.Link className="text-center" href ="/privacy_policy">Политика обработки персональных данных</Nav.Link>
                        </Nav>
                    </Navbar>
            </Row>
        </Container>
    )
}

export default Footer