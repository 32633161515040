import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {checkState} from "../hooks/useAuthState";
import {useEffect, useState} from "react";
import {useCookies} from 'react-cookie'


function AuthPage() {

    const {authMethod} = useParams();
    let [searchParams] = useSearchParams();
    if (authMethod === "google") {
        let currentUrl = window.location.href;
        currentUrl = currentUrl.replace("#", "?");
        searchParams = new URL(currentUrl).searchParams;
    }
    const authCode = searchParams.get('code')
    const authServiceToken = searchParams.get('access_token')
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const TOKEN_KEY = 'token';
    const [cookies, setCookie] = useCookies([TOKEN_KEY])

    useEffect(() => {
        const fetchData = async () => {
            const data = {authCode, authMethod, authServiceToken}
            console.log("Request parameters: " + JSON.stringify(data))
            const response = await fetch(`${process.env.REACT_APP_API_CONCRETE_PRICE_URL}/auth/login`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
            console.log(response)
            const responseJson = await response.json()
            console.log(responseJson)
            const accessToken = responseJson["accessToken"];
            setToken(accessToken)
        }
        if (checkState(searchParams.get("state"))) {
            console.log("received state is valid")
            fetchData().catch(console.error);
        }
    }, [authMethod, authCode, authServiceToken, searchParams])

    const setTokenToCookie = (access_token: string): void => {
        const token = JSON.stringify({
            value: access_token,
            timeStamp: new Date().getTime(),
        })
        setCookie(TOKEN_KEY, token)
    };

    const checkLogin = () => {
        navigate('/checkLogin', {replace: true})
    }

    setTokenToCookie(token)
    checkLogin()
}

export default AuthPage