export default function FbsGroup(groupId) {

    const fbsGroups = [
        {groupId: 'fbs3', groupName: 'Блоки шириной 30 см', names: ['24.3.6', '12.3.6', '9.3.6', '8.3.6', '6.3.6']},
        {groupId: 'fbs4', groupName: 'Блоки шириной 40 см', names: ['24.4.6', '12.4.6', '9.4.6', '8.4.6', '6.4.6']},
        {groupId: 'fbs5', groupName: 'Блоки шириной 50 см', names: ['24.5.6', '12.5.6', '9.5.6', '8.5.6', '6.5.6']},
        {groupId: 'fbs6', groupName: 'Блоки шириной 60 см', names: ['24.6.6', '12.6.6', '9.6.6', '8.6.6', '6.6.6']},
        {groupId: 'fbs8', groupName: 'Блоки шириной 80 см', names: ['24.8.6', '12.8.6', '9.8.6', '8.8.6', '6.8.6']},
        {groupId: 'fbsHeight3', groupName: 'Блоки высотой 30 см', names: ['24.4.3', '12.4.3', '24.5.3', '12.5.3', '24.6.3', '12.6.3']},
        {groupId: 'gardenFbs', groupName: 'Мелкие блоки', names: ['25x25x30', '20x30x30', '30x30x30', '20x20x40', '20x30x60', '90x60x30']}
    ]
    return fbsGroups.find((group) => group.groupId === groupId);
}