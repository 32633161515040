import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from "react-router-dom";
import PriceListContainer from "../components/PriceListContainer";
import {Col, Container, Row} from "react-bootstrap";
import FbsNameButtonToolbar from "../components/FbsNameButtonToolbar";
import {useCookies} from "react-cookie";


function PricePage() {
    const {productName} = useParams();
    const [prices, setPrices] = useState(null);
    const location = useLocation();
    const [cookies, setCookie] = useCookies(['token'])


    useEffect(() => {
        let token = (cookies.token === null)
            ? null
            : "Bearer " + cookies.token
        let address = `${process.env.REACT_APP_API_CONCRETE_PRICE_URL}/web/getPrices?productId=${productName}`

        const fetchData = async () => {
            const data = await fetch(address, {
                method: "GET",
                mode: "cors",
                headers: {
                    "Authorization": token
                }
            })
            const json = await data.json()
            setPrices(json)
        }

        const setPricePageToStorage = () => {
            sessionStorage.setItem(
                'last_price_page',
                JSON.stringify({
                    value: location.pathname
                })
            );
        };

        setPricePageToStorage();
        fetchData().catch(console.error);
    }, [productName, location])


    if (prices !== null) {
        if (prices['priceListSize'] !== 0) {
            return <>
                <Container id="cover-container" className="text-center">
                    <Row>
                        <Col>
                            <h3 align="center">Цены на блоки ФБС {productName}</h3>
                        </Col>
                    </Row>
                </Container>
                <Container id="search-price-container">
                    <Row>
                        <Col>
                            <FbsNameButtonToolbar/>
                        </Col>
                        <Col>
                            <p className="mt-2" align="center">Нажмите на название производителя для просмотра
                                контактной информации</p>
                            <PriceListContainer priceListResponse={prices}/>
                        </Col>
                    </Row>
                </Container>
            </>
        } else {
            return <>
                <Container id="cover-container" className="text-center">
                    <Row>
                        <Col>
                            <h3 align="center">Цены на блоки ФБС {productName}</h3>
                            <p align="center">Для просмотра контактной информации, нажмите на название производителя</p>
                        </Col>
                    </Row>
                </Container>
                <Container id="search-price-container">
                    <Row>
                        <Col>
                            <FbsNameButtonToolbar/>
                        </Col>
                        <Col>
                            <h5 align="center">Цены на ФБС {productName} не найдены</h5>
                        </Col>
                    </Row>
                </Container>
            </>
        }
    }
}


export default PricePage