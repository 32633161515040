import React from "react";
import {Accordion, Card} from "react-bootstrap";
import PriceListItem from "./PriceListItem";
import SellerCard from "./SellerCard";
import ReactDOM from "react-dom/client";

class PriceAccordion extends React.Component {

    render() {
        let priceList = this.props.data
        let bodyId = "priceListItem" + priceList['priceListItemId']

        return (
            <Accordion>
                <Card>
                    <Card.Header>
                        <PriceListItem eventKey="collapseSellerCard" data={this.props.data}
                                       event={() => this.showSellerCard(bodyId, priceList['sellerId'])}>{priceList['sellerName']}</PriceListItem>
                    </Card.Header>
                    <Accordion.Collapse eventKey="collapseSellerCard">
                        <Card.Body id={bodyId}>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }

    async showSellerCard(bodyId, sellerId) {
        let response = await this.insertSellerCard(sellerId)
        const container = document.getElementById(bodyId);
        const sellerCard = ReactDOM.createRoot(container);
        sellerCard.render(
            <SellerCard response={response} key={sellerId}/>
        )
    }

    async insertSellerCard(sellerId) {
        let url = `${process.env.REACT_APP_API_CONCRETE_PRICE_URL}/web/getSellerCard?sellerId=` + sellerId
        const response = await fetch(url)
        const prices = await response.json()
        return JSON.parse(JSON.stringify(prices));
    }
}

export default PriceAccordion;