import React from "react";
import {Col, Container, Row} from "react-bootstrap";

function Cover() {
    return (
        <Container>
            <Row>
                <Col id="Content" className="text-center mt-3">
                    <>
                        <p> Здесь будут отображаться цены. <br/>Нажмите кнопку с наименованием блока. </p>
                        <p>
                            Пример расшифровки ФБС 24.3.6:<br/>
                            24 - длина блока 240см<br/>
                            3 - ширина блока 30см<br/>
                            6 - высота блока 60см
                        </p>
                    </>
                </Col>
            </Row>
        </Container>
    )
}

export default Cover