import React from "react";
import Button from "react-bootstrap/Button";
import {useCookies} from "react-cookie";

function PaymentButton() {

    const [cookies, setCookie] = useCookies(['token'])

    const onClick = async () => {
        let token = (cookies.token === null)
            ? null
            : "Bearer " + cookies.token

        const data = await fetch(`${process.env.REACT_APP_API_CONCRETE_PRICE_URL}/payment/create`, {
            method: 'GET',
            headers: {
                "Authorization": token
            }
        })
        const json = await data.json();
        window.open(json["confirmationUrl"], "_self", "popup")
    }

    return (
        <Button variant="primary" onClick={onClick}>Оплатить</Button>
    )
}

export default PaymentButton;