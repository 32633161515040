import {Col, Container, OverlayTrigger, Row, Tooltip, useAccordionButton} from "react-bootstrap";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import React from "react";

function PriceListItem({children, eventKey, data, event}) {

    const decoratedOnClick = useAccordionButton(eventKey, event);
    let constBlock = <p align="start">{data["price"]}</p>

    if (data["paymentTypeId"] !== 1) {
        constBlock = (
            <>
                <p align="start">{data["price"]}{' '}
                {showValuePrice(data["paymentTypeName"])}
                </p>
            </>
        )
    }

    if (data["qualityTypeId"] !== 1) {
        constBlock = (
            <>
                <p align="start">{data["price"]}{' '}
                {showValuePrice(data["qualityTypeName"])}
                </p>
            </>
        )
    }

    return (
        <Container>
            <Row onClick={decoratedOnClick}>
                <Col className="col-9 justify-content-start">
                    <ButtonToolbar>
                        {children}
                    </ButtonToolbar>
                </Col>
                <Col className="col-3">
                        {constBlock}
                </Col>
            </Row>
        </Container>
    );
}

function showValuePrice(toolTipText) {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {toolTipText}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="bottom"
            delay={{show: 250, hide: 400}}
            overlay={renderTooltip}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 className="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path
                    d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
            </svg>
        </OverlayTrigger>
    )
}


export default PriceListItem;