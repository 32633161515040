
const OAUTH_STATE_KEY = 'react-use-oauth2-state-key';

export const generateState = () => {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let array = new Uint8Array(40);
    window.crypto.getRandomValues(array);
    array = array.map((x: number) => validChars.codePointAt(x % validChars.length));
    return String.fromCharCode.apply(null, array);
};

export const saveState = (state: string) => {
    sessionStorage.setItem(OAUTH_STATE_KEY, state);
};

export const checkState = (receivedState) => {
    const state = sessionStorage.getItem(OAUTH_STATE_KEY);
    return state === receivedState;
};

export const removeState = () => {
    sessionStorage.removeItem(OAUTH_STATE_KEY);
};