import React from 'react'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ym from "react-yandex-metrika";
import {useNavigate} from "react-router-dom";

function FbsNameDropdownButton(props) {

    const navigate = useNavigate();

    let onClick = (productName) => {
        openPricePage(productName)
        sendDataToYandexMetric(productName)
    }

    let openPricePage = (productName) => {
        navigate("/prices/" + productName);
    }

    const sendDataToYandexMetric = (productName) => {
        const goalParams = {productName: productName};
        ym('reachGoal', 'price_button', goalParams);
        return true;
    }

    return (
        <DropdownButton as={ButtonGroup} className="mb-1 ms-2 w-100" variant="secondary"
                        title={props.fbsGroup.groupName}
                        aria-label={props.fbsGroup.groupId + " button group"}>
                {props.fbsGroup.names.map((fbsName) =>
                    <Dropdown.Item key={fbsName} buttonText={fbsName} onClick={() => onClick(fbsName)}>{fbsName}</Dropdown.Item>)}
        </DropdownButton>
    )
}

export default FbsNameDropdownButton;